import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ScreenTitle from '../../common/components/screenTitle/ScreenTitle';
import ScreenContainer from '../../common/components/screenContainer/ScreenContainer';
import Box from 'common/components/box/Box';
import Tabs, { TabItem } from 'common/components/tabs/Tabs';
import styles from './VehicleScreen.module.scss';
import CarDocumentTab from './components/carDocumentTab/CarDocumentTab';
import CarContractTab from './components/carContractTab/CarContractTab';
import CarDashboardTab from './components/carDashboardTab/CarDashboardTab';
import { useHistory, useParams } from 'react-router-dom';
import CarForm from './components/carForm/CarForm'
import Logger from 'common/services/Logger';
import { DATE_FORMAT_DEFAUT, DATE_TIME_FORMAT_DEFAUT, FUELS, LOGGER_LOG_TYPE, SEGMENT_FEATURES } from 'Config';
import { useToasts } from 'react-toast-notifications';
import VehiclesService from 'api/vehicles/VehiclesService';
import { VehicleViewModel } from 'api/vehicles/models/VehicleViewModel';
import NoImage from 'assets/img/asset-default-image.png';
import MoneyFormat from 'common/components/moneyFormat/MoneyFormat';
import { Col, Row } from 'react-flexbox-grid';
import CarChargeTab from './components/carChargeTab/CarChargeTab';
import CarMaintenanceTab from './components/carMaintenanceTab/CarMaintenanceTab';
import CarVerificationTab from './components/carVerificationTab/CarVerificationTab';
import Loading from 'common/services/Loading';
import CarAccidentTab from './components/carAccidentTab/CarAccidentTab';
import DateFormat from 'common/components/dateFormat/dateFormat';
import CarGpsTab from './components/carGpsTab/CarGpsTab';
import { TrackingProvider } from 'api/tracking/enums/TrackingProvider';
import CarAlertsTab from './components/carAlertsTab/CarAlertsTab';
import { UserProfile } from 'api/account/models/UserProfile';
import UsersService from 'api/users/UsersService';
import { useSelector } from 'react-redux';
import { Reducers } from 'store/types';
import CarGlobalChargeTab from './components/carMonthlyChargesTab/CarMonthlyChargesTab';
import CalendarIcon from 'assets/svg/calendar.svg';
import GaugeIcon from 'assets/svg/gauge.svg';
import DropIcon from 'assets/svg/drop.svg';
import TicketIcon from 'assets/svg/ticket.svg';
import FloorBrushIcon from 'assets/svg/floor-brush.svg';
import MediaIcon from 'assets/svg/Grupo_352.svg';
import UserIcon from 'assets/svg/desktop_default_photo.svg';
import ReserveIcon from 'assets/svg/calendar_v_routine.svg';
import IconWithText from 'common/components/iconWithText/IconWithText';
import Label from 'common/components/label/Label';
import dayjs from 'dayjs';
import AlertsCounter from 'common/components/alertsCounter/AlertsCounter';
import HelperModal from 'common/components/helperModal/HelperModal';
import ScreenHeaderButton from 'common/components/screenHeader/ScreenHeaderButton';
import InfoIcon from 'assets/svg/i_info_primaryDark.svg';
import InfoTour from 'common/components/info-tour/InfoTour';
import { VehicleConsumptionsResultDto } from 'api/vehicles/models/VehicleDto';
import ReactTooltip from 'react-tooltip';
import DeletedVehicleCircle from 'common/components/deletedVehicleCircle/DeletedVehicleCircle';

export type Types = 'edit' | 'details' | 'new' | 'list';

const VehicleScreen = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const { vehicleId, type, tabId, itemType, returnUrl } = useParams<{ vehicleId: string, type: Types, tabId: string, itemType: string, itemId: string, returnUrl: string }>();
    const { addToast } = useToasts();
    const imageCacheKey = useRef(new Date().getTime());

    const [tabAux, setTabAux] = useState<string | undefined>(tabId);
    const [item, setItem] = useState<VehicleViewModel>();
    const [consumationTotals, setConsumationTotals] = useState<VehicleConsumptionsResultDto | undefined>(undefined);
    const [averageEfficiencyTooltip, setAverageEfficiencyTooltip] = useState<string | undefined>(undefined);
    const [averageCostTooltip, setAverageCostTooltip] = useState<string | undefined>(undefined);

    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const hasVehiclesWritePolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_VEHICLES_WRITE']);
    const hasVehiclesReadPolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_VEHICLES_READ']);
    const hasContractsReadPolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_CONTRACTS_READ']);
    const hasChargesReadPolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_CHARGES_READ']);
    const hasVehicleMaintenancesReadPolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_VEHICLE_MAINTENANCES_READ']);
    const hasAlertsReadPolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_ALERTS_READ']);
    const hasVerificationsReadPolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_VERIFICATIONS_READ']);
    const hasAccidentsReadPolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_ACCIDENTS_READ']);
    const hasGpsReadPolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_GPS_READ']);
    const hasCountersPolicy = UsersService.hasPolicies(userProfile?.policies || [], ['CHARGES_TOTAL_READ']);
    const hasDashboardPolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_DASHBOARDS_READ']);

    const [tourOpen, setTourOpen] = useState<boolean>(false);
    const steps = [
        {
            selector: '#first-step',
            content: <HelperModal title={t('vehicles.list.tour.details')} text={t('vehicles.list.tour.details_message')} />,
            tab: ''
        }
    ];

    const tabs: TabItem[] = [];
    const [tourReRender, setUpdate] = useState<string>('0');
    const manageTourReRender = () => {
        setUpdate((parseInt(tourReRender) + 1).toString());
    }

    const init = () => {

        if (hasDashboardPolicy) {
            tabs.push({
                id: 'dashboard',
                title: t('vehicle.dashboard'),
                content: <CarDashboardTab tourReRender={manageTourReRender} hasTrackingDeviceId={Boolean(item?.trackingDeviceId)} segmentFeatures={item?.segmentFeatures ?? []}/>
            });
            steps.push({
                selector: '#general-step',
                content: <HelperModal title={t('vehicles.list.tour.dashboard')} text={t('vehicles.list.tour.dashboard_message')} />,
                tab: 'dashboard'
            });
        }

        if (hasVehiclesWritePolicy || hasVehiclesReadPolicy) {
            tabs.push({
                id: 'informations',
                title: t('vehicle.car_document'),
                content: <CarDocumentTab mode={type} item={item} onCancel={onClose} />
            });
            steps.push({
                selector: '#general-step',
                content: <HelperModal title={t('vehicles.list.tour.car_document')} text={t('vehicles.list.tour.car_document_message')} />,
                tab: 'informations'
            });
        }

        if (hasContractsReadPolicy) {
            tabs.push({
                id: 'contract',
                title: t('vehicle.contract'),
                content: <CarContractTab vehicle={item} onDeleteFromList={onDeleteFromList} tourReRender={manageTourReRender} />
            });
            steps.push({
                selector: '#general-step',
                content: <HelperModal title={t('vehicles.list.tour.contract')} text={t('vehicles.list.tour.contract_message')} />,
                tab: 'contract'
            });

            // steps.push({
            //     selector: '#contracts-1',
            //     content: <HelperModal title={'Contratos de Uso'} text='Contratos relacionados com o aluger do veículo.' />,
            //     tab: 'contract'
            // });

            // steps.push({
            //     selector: '#contracts-2',
            //     content: <HelperModal title={'Contratos de Manutenção'} text='Contratos relacionados com a manutenção do veículo ' />,
            //     tab: 'contract'
            // });

            // steps.push({
            //     selector: '#contracts-3',
            //     content: <HelperModal title={'Contratos de Seguro'} text='Lista de contratos de seguro associados ao veiculo' />,
            //     tab: 'contract'
            // });
        }

        if (hasChargesReadPolicy) {
            tabs.push({
                id: 'costs',
                title: t('vehicle.costs'),
                content: <CarChargeTab vehicle={item} onDeleteFromList={onDeleteFromList} tourReRender={manageTourReRender} />
            });
            steps.push({
                selector: '#general-step',
                content: <HelperModal title={t('vehicles.list.tour.costs')} text={t('vehicles.list.tour.costs_message')} />,
                tab: 'costs'
            });
        }

        if (hasVehicleMaintenancesReadPolicy) {
            tabs.push({
                id: 'maintenance',
                title: t('vehicle.maintenance'),
                content: <CarMaintenanceTab vehicle={item} onDeleteFromList={onDeleteFromList} tourReRender={manageTourReRender} />
            });
            steps.push({
                selector: '#general-step',
                content: <HelperModal title={t('vehicles.list.tour.maintenance')} text={t('vehicles.list.tour.maintenance_message')} />,
                tab: 'maintenance'
            });
        }

        if (hasAlertsReadPolicy) {
            tabs.push({
                id: 'alerts',
                title: <>
                    <span className={styles.itemText}>
                        {t('vehicle.alerts')}
                        {(item && (item.totalAlerts ?? 0) > 0) && <AlertsCounter totalAlerts={(item.totalAlerts ?? 0)} className={styles.floatingBadge} size='small' />}
                    </span>
                </>,
                content: <CarAlertsTab vehicle={item} tourReRender={manageTourReRender} />
            });
            steps.push({
                selector: '#general-step',
                content: <HelperModal title={t('vehicles.list.tour.alerts')} text={t('vehicles.list.tour.alerts_message')} />,
                tab: 'alerts'
            });
        }

        if (hasGpsReadPolicy && item?.companyTrackingProvider && item?.companyTrackingProvider !== TrackingProvider.NONE && item.trackingDeviceId) {
            tabs.push({
                id: 'gps',
                title: t('vehicle.gps'),
                content: <CarGpsTab vehicleId={item?.id || ''} tourReRender={manageTourReRender} />
            });
            steps.push({
                selector: '#general-step',
                content: <HelperModal title={t('vehicles.list.tour.gps')} text={t('vehicles.list.tour.gps_message')} />,
                tab: 'gps'
            });
        }

        if (hasVerificationsReadPolicy) {
            tabs.push({
                id: 'verifications',
                title: t('vehicle.verifications'),
                content: <CarVerificationTab vehicle={item} tourReRender={manageTourReRender} />
            });
            steps.push({
                selector: '#general-step',
                content: <HelperModal title={t('vehicles.list.tour.verifications')} text={t('vehicles.list.tour.verifications_message')} />,
                tab: 'verifications'
            });
        }

        if (hasAccidentsReadPolicy) {
            tabs.push({
                id: 'accidents',
                title: t('vehicle.accidents'),
                content: <CarAccidentTab vehicle={item} tourReRender={manageTourReRender} />
            });
            steps.push({
                selector: '#general-step',
                content: <HelperModal title={t('vehicles.list.tour.accidents')} text={t('vehicles.list.tour.accidents_message')} />,
                tab: 'accidents'
            });
        }

        if (hasChargesReadPolicy && hasVehicleMaintenancesReadPolicy && hasContractsReadPolicy && hasCountersPolicy) {
            tabs.push({
                id: 'monthlyCharges',
                title: t('vehicle.monthly_costs', { vat: (userProfile?.useValueWithVat ? t('common.with_vat') : t('common.without_vat')) }),
                content: <CarGlobalChargeTab tourReRender={manageTourReRender} />
            });
            steps.push({
                selector: '#general-step',
                content: <HelperModal title={t('vehicles.list.tour.monthly_charges')} text={t('vehicles.list.tour.monthly_charges_message')} />,
                tab: 'monthlyCharges'
            });
        }

    }

    const getData = async () => {
        try {
            if (vehicleId) {
                Loading.show();
                const result = await VehiclesService.getById(vehicleId);
                if (!!result) {
                    if (result.registrationNumberDate) {
                        result.registrationNumberDate = new Date(result.registrationNumberDate);
                    }

                    const resultConsumation = await VehiclesService.getConsumptionCounters({
                        itemsPerPage: 0, page: 0,
                        vehicleId
                    });
                    setConsumationTotals(resultConsumation);
                    if (resultConsumation) {
                        if (resultConsumation.vehicleConsumptionsByFuel.length > 1) {
                            let aux = '';
                            resultConsumation.vehicleConsumptionsByFuel.forEach(x => {
                                aux += (aux == '' ? '' : ' + ');
                                aux += `${x.averageEfficiency.toFixed(2).replace('.', ',')} ${t((x.fuelName == FUELS.ELECTRIC ? 'vehicle.average_efficienc_eletric_unity' : 'vehicle.average_efficienc_unity') as any)}`;
                            });
                            setAverageEfficiencyTooltip(aux);
                        } else {
                            setAverageEfficiencyTooltip(undefined);
                        }

                        setAverageCostTooltip(`${resultConsumation.totalCost.toFixed(2).replace('.', ',')} € / ${resultConsumation.totalKilometers.toFixed(2).replace('.', ',')} kms = ${resultConsumation.averageCost.toFixed(2).replace('.', ',')} ${t('vehicle.costs_consumption_unit')}`);
                    }
                }

                setItem(result);
                Loading.hide();
            }
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.VEHICLES, `Couldn't get vehicle information `, error);
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
        }
    }


    const canAddVehicles = async () => {
        try {
            Loading.show();
            await VehiclesService.candAddVehicles();
            Loading.hide();
        } catch (error) {
            if (error?.response?.status === 402) {
                addToast(t('common.errors.pack_limit_exceeded'), { appearance: 'warning' });
                history.push(`/account/details/subscription`);
            }
        }
    }

    const navigateToTab = (id: string) => {
        setTabAux(undefined);
        history.push(`/vehicles/details/${vehicleId}/${id}/list`);
    }

    const onClose = (result?: Types) => {
        if (vehicleId) {
            if (result === 'edit') {
                history.push(`/vehicles/details/${vehicleId}`);
            }
        }
    };

    const onDeleteFromList = () => {
        getData();
    }

    const showField = (feature: string) => {
        return (item?.segmentFeatures?.findIndex(x => x == feature) ?? -1) >= 0;
    }

    useEffect(() => {
        if (!vehicleId) {
            canAddVehicles();
        }
        getData();
    }, [vehicleId, tabId, itemType, type]);

    init();

    return (
        <ScreenTitle title={t('vehicles.title')}>
            <ScreenContainer>
                {type == 'new' &&
                    <CarForm onCancel={onClose} />
                }

                {item && type != 'new' &&
                    <>
                        <Box className={`${styles.headerBox} ${item && (item.immobilized || !item.active) ? styles.opacity : ''} ${item && item.immobilized ? styles.opacityBackground : ''} `} id="first-step">
                            {steps.length > 0 && <Row>
                                <Col xs={12} style={{ textAlign: 'right' }}><ScreenHeaderButton icon={InfoIcon} onClick={() => setTourOpen(true)} /></Col>
                            </Row>}
                            <Row>
                                <Col xs={12} md={6} lg={4} className={styles.col} style={{ paddingLeft: 0 }}>
                                    <div className={styles.divDetailsCar}>
                                        <div className={styles.divImage}>
                                            {item && <div className={styles.img} style={{ backgroundImage: item?.photoUrl ? 'url(' + item?.photoUrl + '?_=' + imageCacheKey.current + ')' : 'url(' + NoImage + ')' }} />}
                                            {item.immobilized && <div className={styles.overlay}>
                                                <div className={styles.overlayText}>{t('vehicle.immobilized')}</div>
                                            </div>}
                                        </div>
                                        <div className={styles.divDesc}>
                                            <div className={`${styles.content}`}>
                                                <div className={styles.labelRegistration}>{item?.registrationNumber} {!item.active && <DeletedVehicleCircle id={item.id ?? ''} />}</div>
                                                <div className={`${styles.labelDetails} ${item && !item.active && !item.immobilized ? styles.labelDetailsColor : ''} ${item && !item.active ? styles.colorTextWarning : ''}`}>{(item?.vehicleBrandName ?? '') + (' ') + (item?.vehicleModelName ?? '')}</div>
                                                <div className={`${styles.labelDetails} ${item && !item.active && !item.immobilized ? styles.labelDetailsColor : ''} ${item && !item.active ? styles.colorTextWarning : ''}`}>{item?.vehicleSegmentName + ((item.vehicleFuels?.length || item.colorName) ? ' | ': '')}
                                                    {(item.vehicleFuels?.map((x, i) => (
                                                        <span key={'fuel' + i}> {t(('vehicle.fuels.' + x.fuelName) as any).toString()} |  </span>
                                                    )))}
                                                    {item?.colorName ? (t(('vehicle.colors.' + item?.colorName) as any)) : ''}</div>
                                                {Boolean(item?.vehicleLocals?.length) && <div className={`${styles.labelLocal} ${item && !item.active && !item.immobilized ? styles.labelDetailsColor : ''}`}>{item?.vehicleLocals?.map(x => x.localName).join(', ')}</div>}
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={12} md={6} lg={4} className={`${styles.col} ${styles.borderLeft}`}>
                                    <div className={`${styles.cont}`}>
                                        <div className={`${styles.content}`}>

                                            <div className={styles.info}>
                                                <div className={styles.label}>
                                                    <IconWithText
                                                        icon={UserIcon}
                                                        value={t('vehicle.driver')}
                                                        size={'sm'}
                                                    />
                                                </div>
                                                <div className={styles.text}>
                                                    <Label>{item && item.driverName ? item.driverName : '-'}</Label>
                                                </div>
                                            </div>

                                            <div className={styles.info}>
                                                <div className={styles.label}>
                                                    <IconWithText
                                                        icon={ReserveIcon}
                                                        value={t('vehicle.reserve')}
                                                        size={'sm'}
                                                    />
                                                </div>
                                                <div className={styles.text}>
                                                    {(item && item.reservationAvailable && item.reservationStartDate && item.reservationEndDate)
                                                        ? `${dayjs(item.reservationStartDate).format(DATE_FORMAT_DEFAUT)} — ${dayjs(item.reservationEndDate).format(DATE_FORMAT_DEFAUT)}`
                                                        : '-'
                                                    }
                                                </div>
                                            </div>

                                            {showField(SEGMENT_FEATURES.KM) && <div className={styles.info}>
                                                <div className={styles.label}>
                                                    <IconWithText
                                                        icon={CalendarIcon}
                                                        value={t('vehicle.registration')}
                                                        size={'sm'}
                                                    />
                                                </div>
                                                <div className={styles.text}>{item?.registrationNumberDate ? new Date(item.registrationNumberDate).toLocaleDateString([], { year: 'numeric', month: '2-digit' }) : ''}</div>
                                            </div>}


                                            {showField(SEGMENT_FEATURES.KM) && <div className={styles.info}>
                                                <div className={styles.label}>
                                                    <IconWithText
                                                        icon={GaugeIcon}
                                                        value={t('vehicle.current_kilometers')}
                                                        size={'sm'}
                                                    />
                                                </div>
                                                <div className={styles.text}><MoneyFormat value={item && item.currentKilometers ? item.currentKilometers : '-'} suffix={t('common.kms')} fixedDecimalScale={false} /></div>
                                            </div>}

                                            {hasGpsReadPolicy && item && !!item.currentKilometersTracking && <div className={styles.info}>
                                            <div className={`${styles.text} ${styles.subText}`} data-tip={t('vehicle.kms_on_gps_tooltip') + ' ' + (item.lastKilometersUpdate ? dayjs(item.lastKilometersUpdate).format(DATE_TIME_FORMAT_DEFAUT) : '-')}>
                                                    <MoneyFormat value={item.currentKilometersTracking} suffix={t('vehicle.kms_on_gps')} fixedDecimalScale={false} />
                                                </div>
                                            </div>}

                                        </div>
                                    </div>
                                </Col>
                                <Col xs={12} md={6} lg={4} className={`${styles.col} ${styles.borderLeft}`}>

                                    <div className={`${styles.cont}`}>
                                        <div className={`${styles.content}`}>

                                            {showField(SEGMENT_FEATURES.FUEL) && !!consumationTotals &&
                                                consumationTotals.vehicleConsumptionsByFuel.length == 1 &&
                                                consumationTotals.vehicleConsumptionsByFuel.map((x, i) => (
                                                    <div className={styles.info} key={'fueltotal_' + i}>
                                                        <div className={styles.label}>
                                                            <IconWithText
                                                                icon={DropIcon}
                                                                value={t('vehicle.average_consumption') + ' ' + t(('vehicle.fuels.' + x.fuelName) as any)}
                                                                size={'sm'}
                                                            />
                                                        </div>
                                                        <div className={`${styles.text} ${x.consumption != undefined && styles.divPoint}`} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}
                                                            data-for='global'
                                                            data-tip={x.consumption != undefined ? (x.consumption > (x?.consommationMoyenneReference ?? 0) ? t('vehicle.consommation_moyenne_negative', { current_vehicle_consumption: x.consumption != undefined ? x.consumption.toFixed(2).replace('.', ',') + ' ' + t((x.fuelName == FUELS.ELECTRIC ? 'vehicle.eletric_consumption_unit' : 'vehicle.average_consumption_unit') as any) : '-', reference_vehicle_consumption: (x?.consommationMoyenneReference.toFixed(2).replace('.', ',') ?? '-') + ' ' + t((x.fuelName == FUELS.ELECTRIC ? 'vehicle.eletric_consumption_unit' : 'vehicle.average_consumption_unit') as any) }) : t('vehicle.consommation_moyenne_positive', { current_vehicle_consumption: x.consumption != undefined ? x.consumption.toFixed(2).replace('.', ',') + ' ' + t((x.fuelName == FUELS.ELECTRIC ? 'vehicle.eletric_consumption_unit' : 'vehicle.average_consumption_unit') as any) : '-', reference_vehicle_consumption: (x?.consommationMoyenneReference.toFixed(2).replace('.', ',') ?? '-') + ' ' + t((x.fuelName == FUELS.ELECTRIC ? 'vehicle.eletric_consumption_unit' : 'vehicle.average_consumption_unit') as any) })) : ''}
                                                        >
                                                            {x.consumption != undefined && <MoneyFormat value={x.consumption} suffix={t((x.fuelName == FUELS.ELECTRIC ? 'vehicle.eletric_consumption_unit' : 'vehicle.average_consumption_unit') as any)} fixedDecimalScale={false} decimalScale={2} />}
                                                            {x.consumption != undefined && <div className={`${styles.point} ${x.consumption > (x?.consommationMoyenneReference ?? 0) ? styles.pointRed : styles.pointGreen}`} />}
                                                            {x.consumption == undefined && '-'}
                                                        </div>

                                                    </div>
                                                ))}

                                            {showField(SEGMENT_FEATURES.FUEL) && !!consumationTotals &&
                                                consumationTotals.vehicleConsumptionsByFuel.length > 1 &&
                                                <div className={styles.info}>
                                                    <div className={styles.label}>
                                                        <IconWithText
                                                            icon={DropIcon}
                                                            value={t('vehicle.average_efficiency')}
                                                            size={'sm'}
                                                        />
                                                    </div>
                                                    <div className={`${styles.text} ${consumationTotals.averageEfficiency != undefined && styles.divPoint}`} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}
                                                        data-tip={averageEfficiencyTooltip} data-for='global3'
                                                    >
                                                        {consumationTotals.averageEfficiency != undefined && <MoneyFormat value={consumationTotals.averageEfficiency} prefix={'≈ '} suffix={t('vehicle.average_efficienc_unity')} fixedDecimalScale={false} decimalScale={2} />}
                                                        {consumationTotals.averageEfficiency == undefined && '-'}
                                                    </div>

                                                </div>
                                            }

                                            {showField(SEGMENT_FEATURES.FUEL) && !!consumationTotals && <div className={styles.info}>
                                                <div className={styles.label}>
                                                    <IconWithText
                                                        icon={DropIcon}
                                                        value={t('vehicle.average_cost')}
                                                        size={'sm'}
                                                    />
                                                </div>
                                                <div className={`${styles.text} ${consumationTotals.averageCost != undefined && styles.divPoint}`} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}
                                                    data-tip={averageCostTooltip} data-for='global2'
                                                >
                                                    {consumationTotals.averageCost != undefined && <MoneyFormat value={consumationTotals.averageCost} suffix={t('vehicle.costs_consumption_unit')} fixedDecimalScale={false} decimalScale={2} />}
                                                    {consumationTotals.averageCost == undefined && '-'}
                                                </div>

                                            </div>}

                                            <ReactTooltip id='global' />
                                            <ReactTooltip id='global2' />
                                            <ReactTooltip id='global3' />
                                            <div className={styles.info}>
                                                <div className={styles.label}>
                                                    <IconWithText
                                                        icon={TicketIcon}
                                                        value={t('vehicle.cost')}
                                                        size={'sm'}
                                                    />
                                                </div>
                                                <div className={styles.text}><MoneyFormat fixedDecimalScale={true} value={item?.fines ?? '-'} suffix={t('common.euro')} /></div>
                                            </div>

                                            <div className={styles.info}>
                                                <div className={styles.label}>
                                                    <IconWithText
                                                        icon={FloorBrushIcon}
                                                        value={t('vehicle.last_cleaning')}
                                                        size={'sm'}
                                                    />
                                                </div>
                                                <div className={styles.text}>{item?.lastCleaning ? <DateFormat value={item.lastCleaning} /> : '-'}</div>
                                            </div>

                                            {hasCountersPolicy && <div className={styles.info}>
                                                <div className={styles.label}>
                                                    <IconWithText
                                                        icon={MediaIcon}
                                                        value={t('vehicle.average_monthly_cost', { vat: (userProfile?.useValueWithVat ? t('common.with_vat') : t('common.without_vat')) })}
                                                        size={'sm'}
                                                    />
                                                </div>
                                                <div className={styles.text}>{item?.monthlyCostAverage ? <MoneyFormat fixedDecimalScale={true} value={item?.monthlyCostAverage ?? '-'} suffix={t('common.euro')} /> : '-'}</div>
                                            </div>}

                                        </div>
                                    </div>

                                </Col>
                            </Row>
                        </Box>
                        <div id="general-step">
                            <Tabs
                                items={tabs}
                                activeTabId={tabAux ?? tabId ?? 'informations'}
                                onChange={navigateToTab}
                                emptyTabMessage={t('vehicle.message_no_permission')}
                            />
                        </div>
                    </>
                }

            </ScreenContainer>
            <InfoTour
                steps={steps}
                isOpen={tourOpen}
                onRequestClose={() => setTourOpen(false)}
                changeTab={setTabAux}
                startAt={(tabAux ?? tabId ?? 'informations') != 'informations' ? steps.findIndex(x => x.tab == (tabAux ?? tabId ?? 'informations')) : 0}
                reRender={tourReRender}
                className={styles.tour}
            />

        </ScreenTitle>
    );
}

export default VehicleScreen;